import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../lib/config";
import cache from "../../../lib/cache"; //local storage library
import {
  SortCartUpDown,
  SubmitEditAddToItems,
  SelectedItem,
  RemoveItem,
  SubmitEditItems,
  DeleteSelectedItem,
  SubmitINorder,
  SyncOrder_Item,
  updateCart_,
  SelectAll,
} from "../../../store/cartSlice";
import { ItemOrder } from "../../../store/productsSlice";
import { CloseOrder } from "../../../store/OrderSlice";
import "../../../assets/css/PointCashier/Invoice/InvoiceItem.css";
import { AddToCart } from "../../../store/cartSlice";
import { SwAlert, SwToast } from "../../../lib/SwAlert";
import ClintCard from "./../../PointCashier/HToolBar/ClintCard";

function InvoiceItem() {
  const { CartList, isEdit, isLoading, update } = useSelector(
    (state) => state.cart
  );
  const { IsSelectOrder, IsUpdate } = useSelector((state) => state.Orders);
  const [IsSelected, setIsSelected] = useState(false);
  const [audio] = useState(new Audio(config.get("audio.sound_success")));
  const { selectedList } = useSelector((state) => state.select);
  const [ViewClintCard, setViewClintCard] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //to add cart list to locale storage
    cache.set("CartItem", CartList); //save to local storage cart item

    if (!isEdit && true === update && IsSelectOrder.Invoice_ID) {
      const st_ = setTimeout(() => {
        //if not editing update and if add set new state
        dispatch(updateCart_(CartList));
        SwToast("success", `❤️ success update Items `);
        setIsSelected(false);
      }, 2000);
      return () => {
        clearTimeout(st_);
      };
    }
  }, [CartList, dispatch, isEdit, update, IsSelectOrder]);

  useEffect(() => {
    //if change order select  lod item
    if (IsSelectOrder.Invoice_ID) {
      // if set value
      dispatch(SyncOrder_Item(IsSelectOrder.Invoice_ID));
    }
  }, [IsSelectOrder, dispatch]);

  const handleCatalog = () => {
    if (IsSelectOrder.Invoice_ID) {
      // if set value
      navigate("/View_/" + IsSelectOrder.Invoice_ID);
    }
  };

  const handleSelectAll = () => {
    setIsSelected(!IsSelected);
    dispatch(SelectAll(IsSelected));
  };

  const handleEditAddToItems = () => {
    dispatch(SubmitEditAddToItems());
    setIsSelected(false);
  };

  const handleSubmitEditItems = () => {
    dispatch(SubmitEditItems());
    setIsSelected(false);
  };

  const handleSubmitINorder = () => {
    dispatch(SubmitINorder());
    setIsSelected(false);
  };
  const CloseOrderhandle = (e) => {
    if (IsUpdate)
      dispatch(CloseOrder({ Invoice_ID: IsSelectOrder.Invoice_ID }));
  };

  const handleAddToCart = () => {
    if (IsSelectOrder && IsSelectOrder.Invoice_ID >= 1) {
      if (selectedList.length >= 1) {
        dispatch(AddToCart());
        SwToast("success", "Add successfully in order");
      } else {
        SwToast("error", "Select product to add");
        audio.pause();
        audio.play().onended = function () {
          this.currentSrc = null;
          this.src = "";
          this.srcObject = null;
          this.remove();
        };
      }
    } else {
      SwAlert("Select order ", "Select order first ! ");
    }
  };

  const openModal = () => {
    IsSelectOrder && IsSelectOrder.Invoice_ID >= 1
      ? setViewClintCard(IsSelectOrder)
      : SwAlert("Select order ", "Select order first ! ");
  };

  const closeModal = () => {
    setViewClintCard(false);
  };

  return (
    <>
      <ClintCard ClintInfo={ViewClintCard} closeModal={closeModal} />

      {IsSelectOrder && IsSelectOrder.Invoice_ID ? (
        <div className="cart-wrapper">
          <div className="cart-title">
            <div className="options-container">
              <div
                className="Button-custom Transition"
                onClick={() => handleAddToCart()}
              >
                <i className="fa fa-cart-plus "></i>
              </div>
              <div
                className="Button-custom Transition"
                onClick={() => openModal()}
              >
                <i className="fa fa-info "></i>
              </div>
              {CartList.length >= 1 && (
                <div
                  className="Button-custom Transition"
                  onClick={() => dispatch(ItemOrder())}
                  title="View Order"
                >
                  <i className="fa fa-eye"></i>
                </div>
              )}
              <div
                className="Button-custom   Transition"
                onClick={() => handleCatalog()}
              >
                <i className="fa  fa-mountain"></i>
              </div>
              {CartList.length >= 1 && (
                <div
                  className="Button-custom Transition"
                  onClick={() => dispatch(SortCartUpDown())}
                >
                  <i className="fa fa-arrow-down "></i>
                </div>
              )}
              <div
                className={`Button-custom Transition Check-inOrder ${
                  IsSelected ? "Checked-true" : ""
                }`}
                onClick={() => handleSelectAll()}
              ></div>
              {isEdit && (
                <div
                  className="Button-custom   Transition"
                  onClick={() => handleSubmitINorder()}
                >
                  <i className="fa  fa-check"></i>
                </div>
              )}
              {isEdit && (
                <div
                  className="Button-custom edit Transition"
                  onClick={() => handleEditAddToItems()}
                  title="Update Plus Quantity"
                >
                  <i className="fa  fa-puzzle-piece"></i>
                </div>
              )}
              {isEdit && (
                <div
                  className="Button-custom edit Transition"
                  onClick={() => handleSubmitEditItems()}
                  title="update Quantity"
                >
                  <i className="fa  fa-retweet"></i>
                </div>
              )}
              {isEdit && (
                <div
                  className="Button-custom   Transition"
                  onClick={() => dispatch(DeleteSelectedItem())}
                  title="Delete Selected"
                >
                  <i className="fa  fa-trash"></i>
                </div>
              )}
            </div>
          </div>
          <hr className="style16" />

          <div className="cart-items" id="transparent-scroll">
            {isLoading ? (
              <div className="IsLoading"></div>
            ) : (
              Array.isArray(CartList) &&
              CartList.map(
                (
                  Item,
                  index //INorder
                ) => (
                  <div
                    className={`item  ${
                      Item.INorder ? "INorder" : ""
                    } Transition ${Item.checked ? "item-selected" : ""} `}
                    key={index}
                    onClick={() => dispatch(SelectedItem(Item))}
                  >
                    <div
                      className="productImg"
                      style={{
                        backgroundImage: `url('${
                          config.get("URLs.product_ImgSmThumbnails") +
                          Item.imgUrl
                        }')`,
                      }}
                    ></div>
                    <div className="item-description">
                      <div>
                        <span className="span-price"> {Item.price} </span>
                        <strong> $ </strong> -
                        <span className="span-Q"> {Item.Quantity} </span> Q
                        <span>{Item.price * Item.Quantity} L.E </span>
                      </div>
                      <div className="title">{Item.title}</div>
                    </div>
                    <div
                      className="clearItem Transition"
                      onClick={() => dispatch(RemoveItem(Item))}
                    >
                      <i className="fa fa-times"></i>
                    </div>
                  </div>
                )
              )
            )}
          </div>
          {CartList.length >= 1 && IsUpdate && (
            <button
              type="button"
              onClick={() => CloseOrderhandle()}
              className="btn-success btnOpenOrder"
              style={{ fontSize: "18px" }}
            >
              {" "}
              <i
                className="fa fa-unlock"
                style={{ paddingRight: "5px" }}
              ></i>{" "}
              اغلاق الاوردر
            </button>
          )}
        </div>
      ) : (
        <div>{/* <h2> --- </h2> */}</div>
      )}
    </>
  );
}

export default InvoiceItem;

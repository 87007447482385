import React from "react";
import Modal from "react-modal";
import "../../../assets/css/PointCashier/HToolBar/ClintCard.css";
const icon_call = <i className="fas fa-phone"></i>;

function ClintCard(props) {
  const { ClintInfo, closeModal } = props;
  return (
    <Modal isOpen={!!ClintInfo} onRequestClose={closeModal} ariaHideApp={false}>
      <span className="CloseModal" onClick={closeModal}>
        {" "}
        &times;
      </span>
      <div className="modal-content-clint">
        {ClintInfo && (
          <>
            <h1> {ClintInfo.Client_Name}</h1>
          <div>
              <h3>
              <a href={`tel:${ClintInfo.Client_phone}`}> {icon_call} </a> 
                {ClintInfo.Client_phone} : {ClintInfo.Client_phone_2} 
              </h3>
            
              <h3>  {ClintInfo.Address.City} - {ClintInfo.Address.First_Address}{" "}
              </h3>{" "}
            </div>
          
          </>
        )}
      </div>
    </Modal>
  );
}
export default ClintCard;

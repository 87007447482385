import React, { useState } from "react";
import "../../assets/css/NotInOrders/NotInOrders.css";
import "../../assets/css/NotInOrders/Components/ToolBar.css";
import Item from "./Components/Item_";
import ToolBar from "./Components/ToolBar";
import LeftInOrderList from "./Components/LeftInOrderList";

function NotInOrders() {
  const [Zoom,setZoom] = useState(false);
  const handleZoom = () => {
    setZoom(!Zoom);
  }
  return (
    <>
    <div className="fix-tolBar">
    <ToolBar handleZoom={handleZoom} />
    </div>
      
      <div className="wrapper-NotInOrder">
        <div className="fixContainer">
          <LeftInOrderList />
        </div>

        <Item  Zoom={Zoom}  />
      </div>
    </>
  );
}
export default NotInOrders;

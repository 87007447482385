import config from "../lib/config";
const hostName = window.location.hostname;  
const LiveHost =process.env.REACT_APP_Host_URL;
const apiKey = process.env.REACT_APP_Local_API_KEY;
const apiUrl = process.env.REACT_APP_Local_API_URL;
const apiKey_Live = process.env.REACT_APP_Live_API_KEY;
const apiUrl_Live = process.env.REACT_APP_Live_API_URL;
 
const isLive = hostName === LiveHost || hostName === 'ybe3.com';
const liveConfig = {
  endpoint: {
    baseUrl: apiUrl_Live,
    apiKey: apiKey_Live,
  },
  URLs: {
    HeaderCoverIMG: "/uploads/User/Thumbnails/",
    product_Img: "/uploads/products/",
    product_ImgThumbnails: "/uploads/products/",
    Cover_Thumbnails: "/uploads/Cover/Thumbnails/",
    product_ImgSmThumbnails:      "/uploads/products/SmThumbnails/",
    UserImg: "/uploads/images/Users/smThumbnails/",
    PImage: "/uploads/uiPage/Thumbnails/",
    CatCover: "/uploads/CatCover/",
    CatThumbnails: "/uploads/CatCover/Thumbnails/",
    CustomThumbnails: "/uploads/CatCover/Thumbnails/",
    CoverMessage: "/uploads/CoverMessage/",
    CoverMessageThumbnails: "/uploads/CoverMessage/Thumbnails/",
    CoverMessage_smThumbnails: "/uploads/CoverMessage/smThumbnails/",
    
  },
   audio: {
     sound1: "/images/sound/Notficaton.mp3",
     sound_success: "/images/sound/Alert.mp3",
     sound_Boom: "/images/sound/boom.mp3",
     coins: "/images/sound/coins-spill.mp3",
   },
};

const localConfig = {
  endpoint: {
    baseUrl: apiUrl,
    apiKey: apiKey,
  },
  URLs: {
    userIMG: "http://www.apimg.me/uploads/User/Thumbnails/",
    product_Img: "http://www.apimg.me/uploads/products/",
    product_ImgThumbnails: "http://www.apimg.me/uploads/products/",
    product_ImgSmThumbnails: "http://www.apimg.me/uploads/products/SmThumbnails/",
    Cover_Thumbnails: "http://www.apimg.me/uploads/Cover/Thumbnails/",
    Cover_smThumbnails: "http://www.apimg.me/uploads/Cover/SmThumbnails/",
    UserImg: "http://www.apimg.me/uploads/User/Thumbnails/",
    uiPage: "http://www.apimg.me/uploads/uiPage/Thumbnails/",
    CatCover: "http://www.apimg.me/uploads/CatCover/",
    CatThumbnails: "http://www.apimg.me/uploads/CatCover/Thumbnails/",
    CustomThumbnails: "http://www.apimg.me/uploads/CatCover/Thumbnails/",
    CoverMessage: "http://www.apimg.me/uploads/CoverMessage/",
    CoverMessageThumbnails: "http://www.apimg.me/uploads/CoverMessage/Thumbnails/",
    CoverMessage_smThumbnails: "http://www.apimg.me/uploads/CoverMessage/smThumbnails/",
  },
  audio: {
    sound1: "/images/sound/Notficaton.mp3",
    sound_Boom: "/images/sound/boom.mp3",
    sound_success: "/images/sound/Alert.mp3",
    coins: "/images/sound/coins-spill.mp3",
  },
};



/* export default config.set({
  endpoint: {
    baseUrl: apiUrl_Live,
    apiKey: apiKey_Live,
  },
  URLs: {
    HeaderCoverIMG: "https://www.ybe3.com/uploads/User/Thumbnails/",
    product_Img: "https://www.ybe3.com/uploads/products/",
    product_ImgThumbnails: "https://www.ybe3.com/uploads/products/",
    Cover_Thumbnails: "https://www.ybe3.com/uploads/Cover/Thumbnails/",
    product_ImgSmThumbnails:
      "https://www.ybe3.com/uploads/products/SmThumbnails/",
    UserImg: "https://www.ybe3.com/uploads/images/Users/smThumbnails/",
    PImage: "https://www.ybe3.com/uploads/uiPage/Thumbnails/",
    CatCover: "https://www.ybe3.com/uploads/CatCover/",
    CatThumbnails: "https://www.ybe3.com/uploads/CatCover/Thumbnails/",
    CustomThumbnails: "https://www.ybe3.com/uploads/CatCover/Thumbnails/",
  },
  audio: {
    sound1: "/images/sound/Notficaton.mp3",
    sound_success: "/images/sound/Alert.mp3",
    sound_Boom: "/images/sound/boom.mp3",
    coins: "/images/sound/coins-spill.mp3",
  },
}); */

  
export default config.set(isLive ? liveConfig : localConfig);
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";

 export const getBotID = createAsyncThunk(
  "bot_sting/getBotID",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const BotInfo = endpoint
        .post(
          "bot_stings/sync_bot_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            botToken: _,
          })
        )

        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return BotInfo;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
 
export const SyncButtons = createAsyncThunk(
  "bot_sting/SyncButtons",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
       const BotInfo = endpoint
        .post(
          "bot_stings/sync_btn_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            botId: _,
          })
        ).then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return BotInfo;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
 
export const SaveButton = createAsyncThunk(
  "bot_sting/SaveButton",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      return endpoint
        .post("bot_stings/save_btn_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          Btn: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
     } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const DelButton = createAsyncThunk(
  "bot_sting/DelButton",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const delbutton = endpoint
        .post(
          "bot_stings/del_button_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId), _,
           
          })
        ).then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return delbutton;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
 
export const SyncBotStings = createAsyncThunk(
  "bot_sting/SyncBotStings",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
       const BotInfo = endpoint
        .post(
          "bot_stings/sync_bot_st_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            botId: _,
          })
        ).then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return BotInfo;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
 //SaveSettings
 export const ResetBotSetting = createAsyncThunk(
  "bot_sting/ResetBotSetting",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      _.append("UserId", parseInt(getState().auth.UserId));
    const BotInfo = endpoint
           .post(
             "bot_stings/reset_bot_st_",_
           ).then((response) => {
             return response.data;
           })
           .catch((error) => {
             // console.log(`---Error ---Categories > ${error} ----`);
           });
         return BotInfo;
       } catch (error) {
         return rejectWithValue(error.message);
       }
     }
   );
   
 
export const SaveSettingStartMessage = createAsyncThunk(
  "bot_sting/SaveSettingStartMessage",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      _.append("UserId", parseInt(getState().auth.UserId));
       const BotInfo = endpoint
        .post(
          "bot_stings/save_bot_st_",_
        ).then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return BotInfo;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const initState = {
  isLoading: false,
  start_msg_isLoading: false,
  FileName:'',
  categories: [],
  ButtonList: [],
  BotInfo: [],
  botId: null,
  Block_btn: {
    Button_title: "",
    callback_data: "",
    url:"",
    Button_type: "",
    btn_ID: "",
   },
   BotSting_: [],
  CategoryImgName: "",
  IsUploaded: false,
  error: null,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
  isEdit: false,
};
 

const BotStingsSlice = createSlice({
  name: "bot_sting",
  initialState: initState,
  reducers: {
    ONUpdate: (state) => {

       state.isEdit=false;
       state.start_msg_isLoading = false;
       state.ErrorHandling = false;
       state.SuccessHandling = false;
       state.ErrorHandlingMsg = null;
       state.HandlingSuccessMsg = null;
     },
     EditButton: (state, action) => {
      state.selectedButton = [];
      state.selectedButton = action.payload;
      state.isEdit = true;
    },
  },
  extraReducers: {
    [ResetBotSetting.pending]: (state, action) => {
      state.isLoading = true;
    },
    [ResetBotSetting.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.start_msg_isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [ResetBotSetting.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [SaveSettingStartMessage.pending]: (state, action) => {
      state.start_msg_isLoading = true;
    },
    [SaveSettingStartMessage.fulfilled]: (state, action) => {
      state.start_msg_isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [SaveSettingStartMessage.rejected]: (state, action) => {
      state.start_msg_isLoading = false;
    },
    [getBotID.pending]: (state, action) => {
      state.isLoading = true;
      state.BotInfo =[];
      state.botId=null;

    },
    [getBotID.fulfilled]: (state, action) => {
      state.isLoading = false;
      // console.log(action.payload);
      if (action.payload) {
      state.BotInfo = action.payload;
      state.botId=action.payload.botId
      }else{
        state.BotInfo=action.payload;
      }
    },
    [getBotID.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [SaveButton.pending]: (state, action) => {
       state.isLoading = false;
    },
    [SaveButton.fulfilled]: (state, action) => {
      state.isEdit = false;
       state.isLoading = false;
          state.isLoading = false;
          state.error = null;
          if (action.payload.error) {
            state.ErrorHandling = true;
            state.ErrorHandlingMsg = action.payload.error.message;
          } else if (action.payload.success) {
            state.SuccessHandling = true;
            state.HandlingSuccessMsg = action.payload.success.message;
          }  
    },
    [SaveButton.rejected]: (state, action) => {
      
    },
    [SyncBotStings.pending]: (state, action) => {
      state.isLoading = true;
    },
    [SyncBotStings.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.BotSting_ = action.payload;
    },
    [SyncBotStings.rejected]: (state, action) => {
      state.isLoading = false;    
    },
    [SyncButtons.pending]: (state, action) => {
      state.isLoading = true;
    },
    [SyncButtons.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ButtonList = action.payload;
    },
    [SyncButtons.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [DelButton.pending]: (state, action) => {
      state.isLoading = true;
    },
    [DelButton.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [DelButton.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
 
  },
});
export const {
  Select,
  ONUpdate,EditButton
  
} = BotStingsSlice.actions;
export default BotStingsSlice.reducer;

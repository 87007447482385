import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../lib/config";
import { SelectedItem } from "../../../store/CatalogSlice";
var Barcode = require('react-barcode');

const Item_ = (props) => {
  const { Zoom ,QrView} = props;
  const { ProductCatalog, isLoading, PriceInfo } = useSelector(
    (state) => state.catalog
  );
  const dispatch = useDispatch();
  const d = new Date();
  useEffect(() => {
    setTimeout(() => {
      const Time_ =
        d.getHours() +
        ":" +
        d.getMinutes() +
        "_" +
        d.getDate() +
        "-" +
        d.getMonth() +
        "-" +
        d.getFullYear();
      document.title = `كتالوج فاتورة - #(${ProductCatalog.length})  ${Time_} `;
    }, 100);
  });

  const List =
    ProductCatalog.length >= 1 ? (
      ProductCatalog.map((Item, index) => (
        <div
          onClick={() => dispatch(SelectedItem(Item))}
className={`product Transition ${Item.INorder ? " Selected" : ""} ${Item.checked ? " item-checked" : ""} ${Zoom ? " Zoom" : ""}    ${QrView ? " Qrcode" : ""}   ${QrView&&Item.checked ? " QrcodeSelected" : ""}`}
          style={{ backgroundImage: `url('${!QrView&&config.get("URLs.product_ImgThumbnails") + Item.imgUrl
            }')`,
          }}
          key={Item._id}
        >
          <div>
            {QrView ? (
          <Barcode value={Item.Product_Code} fontSize={11}    />
            ) : (<></>)}
            {PriceInfo ? (
              <div className="_code_ Transition">
                <span>
                  #{Item.Product_Code}{" "}
                  <p>
                    {Item.Quantity} / {Math.floor(Item.price)} L.E
                  </p>{" "}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ))
    ) : (
      <div
        className="Notfound"
        style={{ height: "600px", backgroundSize: "50%" }}
      ></div>
    );
  return <>{isLoading ? <div className="IsLoading"></div> : <>{List}</>}</>;
};

export default Item_;

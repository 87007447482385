import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  SubmitEditItems,
  SubmitEditAddToItems,
  SyncOrder_Item,
  updateOrderItem_,
  switchPrice,
  SortCatalogeUpDown,
  SubmitINorder,
  DeleteSelectedItem,
  CountTotal,
  SetInvoiceId,
  Paste,
  SelectAll,
  FindItemByCode,
} from "../../../store/CatalogSlice";
import { push_catalog_, ONUpdate } from "../../../store/OrderSlice";
import { Copy } from "../../../store/ClipboardSlice";
import { FilterSelected } from "../../../store/CatalogSlice";
import { useNavigate } from "react-router-dom";
import QuantityButtons from "./QuantityButtons";
import Filter from "./Filter";
import FindItem from "./FindItem";
import GroupsTelegram from "./GroupsTelegram";
import "../../../assets/css/View_Catalog/Components/ToolBar.css";
import { SwToast } from "../../../lib/SwAlert";
import Swal from "sweetalert2";

function ToolBar(props) {
  const { handleZoom, handleQr } = props;
  const [IsSelected, setIsSelected] = useState(false);
  const { Clip } = useSelector((state) => state.clipboard);
  const {
    ProductCatalog,
    isSelect,
    TotalSelect,
    FilterBy,
    OrderBy,
    total,
    isEdit,
    invoiceId,
    update_catalog,
  } = useSelector((state) => state.catalog);
  const {
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
  } = useSelector((state) => state.Orders);
  const [Visible, setVisible] = useState(true);

  const match = { params: useParams() };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 🎉  ${HandlingSuccessMsg}`);
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
    }
    dispatch(ONUpdate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ErrorHandling, SuccessHandling, ErrorHandlingMsg, HandlingSuccessMsg]);

  useEffect(() => {
    let ID = match.params.id;
    if (ID >= 1) {
      setTimeout(() => {
        dispatch(SetInvoiceId(ID));
        dispatch(SyncOrder_Item(ID));
      }, 100);
    }
  }, [dispatch, invoiceId, match.params.id]);

  useEffect(() => {
    dispatch(CountTotal());
  }, [ProductCatalog, dispatch]);

    useEffect(() => {
      //to add cart list to locale storage
      if (!isEdit && true === update_catalog) {
        const st_ = setTimeout(() => {
          if (ProductCatalog.length >= 1) {
            //if not editing update and if add set new state
            dispatch(updateOrderItem_(ProductCatalog));
            SwToast("success", "successfully update order ");
          }
        }, 1000);
        return () => {
          clearTimeout(st_);
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isEdit, update_catalog]);

  const handelSendCatalog = () => {
    let ID = match.params.id;
    dispatch(push_catalog_(ID));
  };

  const handleSelectAll = () => {
    setIsSelected(!IsSelected);
    dispatch(SelectAll(IsSelected));
  };

  const handlePrint = () => {
    window.print();
  };
  const handleBack = (path) => {
    navigate(path);
  };
  const handelSubmitted = () => {
    dispatch(SubmitINorder());
    setIsSelected(false);
   };

  //dispatch(DeleteSelectedItem())

  const handleDelete = (path) => {
    if (isSelect) {
      Swal.fire({
        title: "حذف منتجات ?",
        text: "هل انت متاكد من طلب الحذف للاصناف المحددة !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "الغاء",
        confirmButtonText: "نعم, احذفها !",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(DeleteSelectedItem());
          Swal.fire("تم الحــذف !", "تم حذف المتجات المحددة.", "success");
         
        }
      });
    } else {
      return alertMessage();
    }
  };

  const alertMessage = (e) => {
    let timerInterval;
    Swal.fire({
      title: "حـدد منتج",
      html: "يجب تحديد منتجات اولاً  !",
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log('I was closed by the timer')
      }
    });
  };
  const handelQrView = () => {
    dispatch(switchPrice());
    handleQr();
  }

  const handleFilterSelected = () => {
    setVisible(!Visible);
    dispatch(FilterSelected((Visible ? 1 : 0)))
  };
const handleUpDwn = () => {
  setVisible(!Visible);
  dispatch(SortCatalogeUpDown((Visible ? 1 : 0)))
 }
  return (
    <div className="NavControls">
      <div className="Nav-options">

        <div
          className="AtoCalc Transition BTN_"
          onClick={() => handleBack("/home")}
          title=""
        >
          {" "}
          <i className="fa fa-home "></i>{" "}
        </div>
        <div
          className="AtoCalc Transition BTN_"
          onClick={() => handleBack("/NotInOrders")}
          title=""
        >
          {" "}
          <i className="fa fa-charging-station"></i>{" "}
        </div>
        <div
          className="AtoCalc Transition BTN_"
          onClick={() => handleBack("/cashier")}
          title=""
        ><i className="fa fa-cash-register "></i> </div>
        <div
          className="AtoCalc Transition BTN_"
          onClick={() => handleZoom()}
          title={"تكبير / تصغير"}
        >
          <i className="fas fa-search-plus"></i>
        </div>
        <div
          className="AtoCalc Transition BTN_"
          onClick={() => dispatch(switchPrice())}
        ><i className="fa fa-eye "></i></div>
        <div
          className="AtoCalc Transition BTN_"
 
          onClick={() => handleUpDwn()} title={'فرز تبديل'}
        >
          <i className="fa fa-sort "></i>
        </div>
        <div
          className="AtoCalc Transition BTN_"
          title="فرز للاعلى"
          onClick={() => handleFilterSelected()}
        >
          <i className="fa fa-sort-amount-down "></i>
        </div>

        <div
          className="AtoCalc Transition BTN_"
          onClick={() => handelQrView()}
        >
          <i className="fa fa-qrcode "></i>{" "}
        </div>



        <Filter FilterBy={FilterBy} OrderBy={OrderBy} /> <QuantityButtons />
        <div
          className="AtoCalc Transition BTN_"
          id="TotalSelProducts"
          title="قيمة المحدد "
          style={{ width: "fit-content" }}
        >
          {" "}
          {String(TotalSelect).replace(/(.)(?=(\d{3})+$)/g, "$1,")}{" "}
        </div>
        <div
          className="AtoCalc Transition BTN_"
          id="TotalSelProducts"
          title="منتج"
        >
          {" "}
          {total}{" "}
        </div>
        <div
          className="AtoCalc Transition BTN_"
          id="TotalSelProducts"
          title="منتج محدد "
        >
          {" "}
          {isSelect}{" "}
        </div>
        <div
          className="AtoCalc Transition BTN_"
          id="TotalSelProducts"
          title="صنف"
        >
          {" "}
          {ProductCatalog.length}{" "}
        </div>
        <div
          className="AtoCalc Transition BTN_"
          title="Paste Item"
          style={Clip.length >= 1 ? { color: "#21aced" } : {}}
          onClick={() => (Clip.length >= 1 ? dispatch(Paste()) : null)}
        >
          {" "}
          <i className="fa fa-paste "></i>{" "}
        </div>
        <div
          className="AtoCalc Transition BTN_"
          title="Copy Selected Item"
          onClick={() => dispatch(Copy())}
        >
          {" "}
          <i className="fa fa-clipboard "></i>{" "}
        </div>
        <div
          className="AtoCalc Transition BTN_"
          onClick={() => handleBack(`/print_/${invoiceId}`)}
        >
          <i className="fa fa-print "></i>{" "}
        </div>



        <div
          className="AtoCalc Transition BTN_"
          style={{ color: "#8f1eb4" }}
          title=""
          onClick={() => handlePrint()}
        >
          {" "}
          <i className="fa fa-print "></i>{" "}
        </div>

        <div
          style={{ border: "1px dashed rgb(53 170 243)" }}
          className={`AtoCalc Transition BTN_ Transition Check-inOrder ${IsSelected ? "Checked-true" : ""
            }`}
          onClick={() => handleSelectAll()}
        ></div>

        <FindItem dispatch={dispatch} FindItemByCode={FindItemByCode} />
        {isEdit && isSelect >= 1 && (
          <>
            <div
              className="AtoCalc Transition BTN_"
              title=""
              onClick={() => dispatch(SubmitEditItems())}
            >
              {" "}
              <i className="fa fa-retweet "></i>{" "}
            </div>
            <div
              className="AtoCalc Transition BTN_"
              title=""
              onClick={() => dispatch(SubmitEditAddToItems())}
            >
              {" "}
              <i className="fa fa-puzzle-piece "></i>{" "}
            </div>
            <div
              className="AtoCalc Transition BTN_"
              title=""
              onClick={() => handelSubmitted()}
            >
              {" "}
              <i className="fa fa-check "></i>{" "}
            </div>
            <div
              className="AtoCalc Transition BTN_"
              title=""
              onClick={() => handleDelete()}
            >
              {" "}
              <i className="fa fa-trash "></i>{" "}
            </div>
          </>
        )}
        <GroupsTelegram />
        <div
          className="AtoCalc Transition BTN_"
          title=""
          onClick={() => handelSendCatalog()}
        >
          {" "}
          <i className="fa fa-retweet "></i>{" "}
        </div>
      </div>
    </div>
  );
}

export default ToolBar;

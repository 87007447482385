import React ,{useEffect} from "react";
import LayoutPages from './LayoutPages'
import ViewCatalogIndex from '../components/NotInOrders/NotInOrders';
function UnavailableProducts() {
  // const d = new Date();
  // const Time_ =d.getHours()+":"+d.getMinutes()+"_"+ d.getDate()+"-"+d.getMonth()+"-"+d.getFullYear();
  useEffect(() => {
    setTimeout(() => {
      document.title =(`ادارة الاوردرات`);
     }, 100);
    });
  return ( 
    <LayoutPages LeftSidebarHide={false} >
     <ViewCatalogIndex/>
    </LayoutPages>
  
    )
}

export default UnavailableProducts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
import cache from "../lib/cache"; //local storage library
import { CloseOrder } from "./OrderSlice";

export const SyncOrder_Item = createAsyncThunk(
  "clint/SyncOrder_Item",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const OrderDetailers_ = endpoint
        .get(`orders/OrderDetailes_/${_}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error --- > ${error} ----`);
        });
      return OrderDetailers_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCart_ = createAsyncThunk(
  "Orders/updateCart_",
  async (post, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const invoiceId = getState().Orders.IsSelectOrder.Invoice_ID;
    const result = post.map((e) => {
      const { title, Product_Code, imgUrl, description, sizes, ...Res } = e; //remove this key from list items
      return Res;
    });

    try {
      const NewOrder = endpoint
        .post(
          "orders/update_cart_",
          JSON.stringify({ invoiceId: invoiceId, Items: result })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return NewOrder;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const AddToCart = createAsyncThunk(
  "cart/AddToCart",
  async (Items, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      //get list selected items to push in cart list
      return [
        getState().select.selectedList,
        getState().Quantity.QuantityVal,
        getState().Orders.IsSelectOrder,
      ];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const AddItemToCart = createAsyncThunk(
  "cart/AddItemToCart",
  async (Item, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      //get list selected items to push in cart list
      return [
        Item,
        getState().Quantity.QuantityVal,
        getState().Orders.IsSelectOrder,
      ];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SubmitEditItems = createAsyncThunk(
  "cart/SubmitEditItems",
  async (Items, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      //get Quantity value to replace last Quantity
      return getState().Quantity.QuantityVal;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SubmitEditAddToItems = createAsyncThunk(
  "cart/SubmitEditAddToItems",
  async (Items, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      //get Quantity value and add to last Quantity +
      return getState().Quantity.QuantityVal;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initState = {
  isLoading: false,
  CartList: cache.get("CartItem") || [],
  isEdit: false,
  update: false,
  error: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initState,
  reducers: {
    SelectAll: (state, action) => {
      //select all in cart list item
      state.CartList.map((Item) => (Item.checked = !action.payload));
      !action.payload ? (state.isEdit = true) : (state.isEdit = false);
    },
    ClearCart: (state, action) => {
      //remove the item from the cart list
      state.CartList = [];
    },
    RemoveItem: (state, action) => {
      //remove the item from the cart list
      state.CartList = state.CartList.filter(
        (el) => el.id !== action.payload.id
      );
      state.update = true;
    },
    SortCartUpDown: (state, action) => {
      state.CartList.sort((a, b) => b.INorder - a.INorder);
    },
 
    DeleteSelectedItem: (state, action) => {
      //delete multiple items from cart list
      let obj = state.CartList.filter((o) => o.checked === true);
      obj.map((Item, index) => {
        state.CartList = state.CartList.filter((el) => el.id !== Item.id);
        return null;
      });
      state.update = true;
      state.isEdit = false;
    },
    SubmitINorder: (state, action) => {
      //check multiple selected item INorder true
      let obj = state.CartList.filter((o) => o.checked === true);
      obj.map((Item, index) => {
        Item.checked = false;
        Item.INorder = !Item.INorder;
        return null;
      });
      state.update = true;
      state.isEdit = false;
    },
    SelectedItem: (state, action) => {
      //selected item in cart list
      let obj = state.CartList.find((o) => o.id === action.payload.id);
      if (obj) {
        obj.checked = !obj.checked;
        state.isEdit = true;
      }
    },
  },
  extraReducers: {
    [updateCart_.pending]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
    },
    [updateCart_.fulfilled]: (state, action) => {
      // state.isLoading = false;
      state.error = null;
      state.update = false;
      state.isEdit = false;
    },
    [updateCart_.rejected]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
    },
    [SyncOrder_Item.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncOrder_Item.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.CartList = action.payload;
      // console.log(action.payload);
      state.update = false;
    },
    [SyncOrder_Item.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [AddToCart.pending]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
    },
    [AddToCart.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload[2].Invoice_ID >= 1) {
        //if  already selected order add item to cart list
        // console.log(action.payload[2].Invoice_ID);
        action.payload[0].map((i, x) => {
          let obj = state.CartList.find((o) => o.id === i.id);
          !obj
            ? state.CartList.push({
                ...i,
                Quantity: action.payload[1],
                Invoice_ID: action.payload[2].Invoice_ID,
              })
            : (obj.Quantity =
                parseInt(obj.Quantity) + parseInt(action.payload[1]));
          return null;
        });
      }
      state.update = true;
    },
    [AddToCart.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [AddItemToCart.pending]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
    },
    [AddItemToCart.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload[2].Invoice_ID >= 1) {
        //if  already selected order add item to cart list
      const Item=  action.payload[0] 
         let obj = state.CartList.find((o) => o.id === Item.id);
        !obj
        ? state.CartList.push({...Item,  Quantity: action.payload[1],  Invoice_ID: action.payload[2].Invoice_ID     })
        : (obj.Quantity =
        parseInt(obj.Quantity) + parseInt(action.payload[1]));
      }
      state.update = true;
    },
    [AddItemToCart.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SubmitEditItems.fulfilled]: (state, action) => {
      let obj = state.CartList.filter((o) => o.checked === true);
      obj.map((Item, index) => {
        Item.checked = false;
        Item.Quantity = action.payload;
        return null;
      });
      state.isEdit = false;
      state.update = true;
    },
    [SubmitEditAddToItems.fulfilled]: (state, action) => {
      let obj = state.CartList.filter((o) => o.checked === true);
      obj.map((Item, index) => {
        Item.checked = false;
        Item.Quantity = parseInt(Item.Quantity) + parseInt(action.payload);
        return null;
      });
      state.isEdit = false;
      state.update = true;
    },

    [CloseOrder.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.CartList = [];
        cache.remove("CartItem"); //save to local storage cart item
      }
      // console.log(action.payload);
      // state.order_info = action.payload;
    },
  },
});
export const {
  SortCartUpDown,
  SelectedItem,
  RemoveItem,
  DeleteSelectedItem,
  SubmitINorder,
  ClearCart,
  SelectAll,
} = cartSlice.actions;
export default cartSlice.reducer;
